import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`KCD Community on Discord`}</strong>{` is a community of people who want to make
connections, share ideas, and use software to help make the world a better
place. All in an environment that's friendly and respectful (please read and
follow the `}<a parentName="p" {...{
        "href": "/conduct"
      }}>{`Code of Conduct`}</a>{`).`}</p>
    <p><strong parentName="p">{`And you're invited to join.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://kcd.im/discord"
      }}>{`Click here to join the KCD Community on Discord`}</a></p>
    <h2 {...{
      "id": "things-to-do",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#things-to-do",
        "aria-label": "things to do permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Things to do`}</h2>
    <h3 {...{
      "id": "onboarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#onboarding",
        "aria-label": "onboarding permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Onboarding`}</h3>
    <p>{`The first task when joining is the onboarding process. The KCD Bot will create a
channel for you. The Bot will proceed to ask questions that are mandatory to
continue onto the server with everyone else.`}</p>
    <p>{`In addition to general chat, there are lots of ways to learn and grow with
others in the KCD Community on Discord. Here are a few ideas:`}</p>
    <h3 {...{
      "id": "kents-live-streams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kents-live-streams",
        "aria-label": "kents live streams permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Kent's Live Streams`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`💻 Kent Live`}</inlineCode>{` video channel and the accompanying `}<inlineCode parentName="p">{`#💬-kent-live`}</inlineCode>{` chat
channel are where I do my live streams. Most of the time my live streams are me
coding projects or working on open source.`}</p>
    <h3 {...{
      "id": "kents-office-hours",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kents-office-hours",
        "aria-label": "kents office hours permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Kent's Office Hours`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`🏫 Kent's Office Hours`}</inlineCode>{` video channel and the `}<inlineCode parentName="p">{`🏫-officehours`}</inlineCode>{` chat channel
are where I host my `}<a parentName="p" {...{
        "href": "/office-hours"
      }}>{`office hours`}</a>{`. This is a time twice a week
where I go live and answer any question you have. We can even share screens.
Really anything you're working on or any general questions you have can be
answered during my office hours.`}</p>
    <h3 {...{
      "id": "kcd-learning-clubs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kcd-learning-clubs",
        "aria-label": "kcd learning clubs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`KCD Learning Clubs`}</h3>
    <p>{`When we learn together, we learn better, and that's the idea behind the
`}<a parentName="p" {...{
        "href": "/clubs"
      }}>{`KCD Learning Clubs`}</a>{`. I definitely recommend taking advantage of these
to enhance your own learning experience with my course material or anything else
you want to learn.`}</p>
    <h2 {...{
      "id": "moderators",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#moderators",
        "aria-label": "moderators permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Moderators`}</h2>
    <p>{`Moderators are responsible for being good examples of the
`}<a parentName="p" {...{
        "href": "/conduct"
      }}>{`Code of Conduct`}</a>{`. They politely encourage others to do so if they
notice slip-ups. Moderators are the gardeners of the Discord. If you're active
and helpful in the community, me or my mod team will reach out to you for joining
us as a moderator for the KCD discord.`}</p>
    <h2 {...{
      "id": "categories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#categories",
        "aria-label": "categories permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Categories`}</h2>
    <p>{`We are always open to more suggestions for additional channels.`}</p>
    <ul>
      <li parentName="ul">{`KCD: is where scheduled meetups with Kent occur.`}</li>
      <li parentName="ul">{`General Channels: has a little something for everyone.`}</li>
      <li parentName="ul">{`React: Channels for support and discussion about React, React frameworks, and
much more.`}</li>
      <li parentName="ul">{`Epic React: we have channels for helping & discussing every aspect of Epic
React 🚀.`}</li>
      <li parentName="ul">{`Testing JavaScript: we have channels for helping & discussing every aspect of
Testing JavaScript 🏆`}</li>
      <li parentName="ul">{`Clubs: Tired of learning by yourself!? This is where you can find or create
study groups, how fun 😊!`}</li>
      <li parentName="ul">{`Advice: If you are looking for professional opinions or thoughts on various
topics, this a good start.`}</li>
      <li parentName="ul">{`Other: This is where our bot lives 🤖`}</li>
      <li parentName="ul">{`Moderation: Looking to help the community as it grows, come join Moderators,
exemplary examples of our community.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      