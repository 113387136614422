import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
import Subscribe from 'components/forms/subscribe';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Kent C. Dodds`}</strong>{` is a world-renowned JavaScript software engineer and teacher.
He's taught hundreds of thousands of people all over the globe how to make the
world a better place with quality software development tools and practices. He
lives with his wife and four kids in Utah.`}</p>
    <p><strong parentName="p">{`Here's how this works:`}</strong></p>
    <ol>
      <li parentName="ol">{`You give me your email address`}</li>
      <li parentName="ol">{`I send you an email every week`}</li>
      <li parentName="ol">{`You get better at building quality software`}</li>
    </ol>
    <Subscribe style={{
      margin: '40px auto'
    }} mdxType="Subscribe" />
    <p>{`I focus on JavaScript for the browser and Node. I'm pretty into React, Testing,
tooling, and Babel. My weekly emails reflect this preference. I'll occasionally
give career advice as well. It's normally pretty brief and reflects what I'm
learning and currently working on, or what people are asking me on
`}<a parentName="p" {...{
        "href": "https://kcd.im/ama"
      }}>{`my AMA`}</a>{`.`}</p>
    <small>
  I will not sell your email address to spammers. I will occasionally send
  emails outside the regular cadence.
    </small>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      