import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "uses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#uses",
        "aria-label": "uses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Uses`}</h1>
    <p>{`I regularly get messages asking about the specifics of some piece of software or
hardware I use. When they do, I send them this page.`}</p>
    <blockquote>
      <p parentName="blockquote">{`And you can too! The short URL for this page is
`}<a parentName="p" {...{
          "href": "https://kcd.im/uses"
        }}><inlineCode parentName="a">{`kcd.im/uses`}</inlineCode></a></p>
    </blockquote>
    <p>{`If there is something missing ask in my `}<a parentName="p" {...{
        "href": "https://kcd.im/ama"
      }}>{`AMA`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Full disclosure, all amazon links are affiliate links. Thank you <3`}</p>
    </blockquote>
    <h2 {...{
      "id": "editor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#editor",
        "aria-label": "editor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Editor`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com"
        }}>{`Visual Studio Code`}</a>{` - Find my settings here:
`}<a parentName="li" {...{
          "href": "https://kcd.im/vscode"
        }}>{`kcd.im/vscode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.visualstudio.com/items?itemName=sdras.night-owl&WT.mc_id=twitter-social-sdras"
        }}>{`Night Owl`}</a>{`
editor theme by `}<a parentName="li" {...{
          "href": "https://twitter.com/sarah_edo"
        }}>{`Sarah Drasner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dank.sh"
        }}>{`Dank Mono`}</a>{` font by
`}<a parentName="li" {...{
          "href": "https://twitter.com/_philpl"
        }}>{`Phil Plückthun`}</a></li>
    </ul>
    <h2 {...{
      "id": "chrome-extensions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#chrome-extensions",
        "aria-label": "chrome extensions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Chrome Extensions`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://palettab.com"
        }}>{`PaletTab (New Tab Page)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/1password-extension-deskt/aomjjhallfgjeglblehebfpbcfeobpgk"
        }}>{`1Password extension (desktop app required)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/contributors-on-github/cjbacdldhllelehomkmlniifaojgaeph"
        }}>{`Contributors on GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/github-%201s/lddfkkebajnpiicnpfamebilmhamkeme"
        }}>{`GitHub +1s`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/github-repository-size/apnjnioapinblneaedefcnopcjepgkci"
        }}>{`GitHub Repository Size`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/octolinker/jlmafbaeoofdegohdhinkhilhclaklkp"
        }}>{`OctoLinker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/password-alert/noondiphcddnnabmjcihcjfbhfklnnep"
        }}>{`Password Alert`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/react-developer-tools/fmkadmapgofadopljbjfkapdkoienihi"
        }}>{`React Developer Tools`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/refined-github/hlepfoohegkhhmjieoechaddaejaokhf"
        }}>{`Refined GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/tab-size-on-github/ofjbgncegkdemndciafljngjbdpfmbkn"
        }}>{`Tab Size on GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm"
        }}>{`uBlock Origin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/unpkg-link/edafhclpiklopbghoeoefdajoadokdcb"
        }}>{`unpkg Link`}</a></li>
    </ul>
    <h2 {...{
      "id": "desktop-apps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#desktop-apps",
        "aria-label": "desktop apps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Desktop Apps`}</h2>
    <p>{`You can find all the apps I auto-install when I setup a new MacBook in
`}<a parentName="p" {...{
        "href": "https://github.com/kentcdodds/dotfiles/blob/master/.macos"
      }}>{`my dotfiles `}<inlineCode parentName="a">{`.macos`}</inlineCode>{` script`}</a>{`.
Here are a few highlights (in no particular order):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.google.com/chrome"
        }}>{`Google Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.alfredapp.com"
        }}>{`Alfred App`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://folivora.ai"
        }}>{`BetterTouchTool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mizage.com/divvy"
        }}>{`Divvy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.macbartender.com"
        }}>{`Bartender`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mowglii.com/itsycal"
        }}>{`Itsycal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://1password.com"
        }}>{`1Password`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kapeli.com/dash"
        }}>{`Dash`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Terminal_(macOS)"
        }}>{`Terminal.app`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://spotify.com"
        }}>{`Spotify`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://slack.com"
        }}>{`Slack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discord.com"
        }}>{`Discord`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kyome.io/runcat/index.html"
        }}>{`RunCat`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://todoist.com/r/kent_c_dodds_rcfmfh"
        }}>{`Todoist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://workflowy.com/invite/a9f7933.lnx"
        }}>{`Workflowy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gif.ski"
        }}>{`Gifski`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zoom.us"
        }}>{`Zoom.us`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sipapp.io"
        }}>{`sip`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://heyfocus.com"
        }}>{`Focus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://frontapp.com"
        }}>{`Front`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jaredly/qmoji"
        }}>{`Qmoji`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dropbox.com"
        }}>{`Dropbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sindresorhus.com/battery-indicator"
        }}>{`Battery Indicator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.telestream.net/screenflow"
        }}>{`ScreenFlow`}</a>{` - Recording software`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://obsproject.com"
        }}>{`OBS`}</a>{` - Live streaming software`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/avibrazil/RDM"
        }}>{`RDM`}</a>{` - For setting the screen size`}</li>
    </ul>
    <h2 {...{
      "id": "clis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#clis",
        "aria-label": "clis permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`CLIs`}</h2>
    <p>{`Again, you can find everything that I auto-install when I setup a new MacBook in
`}<a parentName="p" {...{
        "href": "https://github.com/kentcdodds/dotfiles/blob/master/.macos"
      }}>{`my dotfiles `}<inlineCode parentName="a">{`.macos`}</inlineCode>{` script`}</a>{`.
Here are a few highlights:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brew.sh/"
        }}>{`Homebrew`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/hub"
        }}>{`hub`}</a>{` (a better git)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/BurntSushi/ripgrep"
        }}>{`ripgrep`}</a>{` (a better grep)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://formulae.brew.sh/formula/tree"
        }}>{`tree`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sharkdp/bat"
        }}>{`bat`}</a>{` (a better cat)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dandavison/delta"
        }}>{`delta`}</a>{` (a better git diff)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/batpigandme/night-owlish"
        }}>{`night-owlish`}</a>{` (theme for bat and
delta)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://npm.im/serve"
        }}>{`serve`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://npm.im/npm-quick-run"
        }}>{`npm-quick-run`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://npm.im/npm-check-updates"
        }}>{`npm-check-updates`}</a></li>
    </ul>
    <h2 {...{
      "id": "my-office",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#my-office",
        "aria-label": "my office permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`My Office`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9db13a84d00f3a118a3ca431d5f59cdf/27b8c/office.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.61003861003861%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABRJYA/8QAGhAAAQUBAAAAAAAAAAAAAAAAEQABAgQSIv/aAAgBAQABBQKzl58kr//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABAhD/2gAIAQEABj8CROf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMUGR/9oACAEBAAE/IcHDahiSUedL6f/aAAwDAQACAAMAAAAQh+//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQMBAT8QMZ//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQIBAT8QdJ//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMVFBsf/aAAgBAQABPxAo7OHucfYitgsaV0L2CyVrtZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "My Office",
            "title": "My Office",
            "src": "/static/9db13a84d00f3a118a3ca431d5f59cdf/af659/office.jpg",
            "srcSet": ["/static/9db13a84d00f3a118a3ca431d5f59cdf/8356d/office.jpg 259w", "/static/9db13a84d00f3a118a3ca431d5f59cdf/bc760/office.jpg 518w", "/static/9db13a84d00f3a118a3ca431d5f59cdf/af659/office.jpg 1035w", "/static/9db13a84d00f3a118a3ca431d5f59cdf/51eb8/office.jpg 1553w", "/static/9db13a84d00f3a118a3ca431d5f59cdf/33042/office.jpg 2070w", "/static/9db13a84d00f3a118a3ca431d5f59cdf/27b8c/office.jpg 2666w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can also look at a (pretty bad)
`}<a parentName="p" {...{
        "href": "https://photos.app.goo.gl/MeDfjFhfqdnU9Dq38"
      }}>{`photosphere of my office on Google Photos`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=N3wY9kNnmQA"
      }}>{`Watch the office tour`}</a>{`:`}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/N3wY9kNnmQA?rel=0",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <h3 {...{
      "id": "desk-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#desk-setup",
        "aria-label": "desk setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Desk Setup`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.apple.com/macbook-pro-16/"
        }}>{`MacBook Pro (16-inch, 2019)`}</a>{` - My
laptop. More stats:`}
        <ul parentName="li">
          <li parentName="ul">{`Processor: 2.4 GHz 8-Core Intel Core i9`}</li>
          <li parentName="ul">{`Memory: 64 GB 2667 MHz DDR4`}</li>
          <li parentName="ul">{`Graphics: AMD Radeon Pro 5500M 8 GB, Intel UHD Graphics 630 1536 MB`}</li>
          <li parentName="ul">{`Storage: 2TB SSD`}</li>
        </ul>
      </li>
      <li parentName="ul">{`My desk is a custom made standing desk (my wife made it for me for a Christmas
present) (so sorry, no Amazon link).`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00V4LYKEQ?tag=kentcdodds-20"
        }}>{`Homelegance Daria 5-Tier Bookcase, 26" W, Brown`}</a>{` -
This is where I put all my auxiliary equipment`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07JMH6BSY?tag=kentcdodds-20"
        }}>{`OWC Thunderbolt 3 Dock`}</a>{` - is the main box
on my bookcase all wires ultimately come together before going into my laptop.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01H5QF2TK/?tag=kentcdodds-20"
        }}>{`Cable Matters Thunderbolt 3 Cable`}</a>{` - This
connects my laptop to the dock. It's the only cable to goes into my laptop.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07XV9NQSJ?tag=kentcdodds-20"
        }}>{`LG 27MD5KL-B 27 Inch Ultrafine 5K (5120 x 2880) IPS Display with macOS Compatibility, DCI-P3 99% Color Gamut and Thunderbolt 3 Port, Black`}</a>{` -
I have 2 of these`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01DYFI1OE?tag=kentcdodds-20"
        }}>{`Cable Matters Thunderbolt 3 to Dual DisplayPort Adapter`}</a>{` -
This connects the monitors to the dock.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B013PWQN1Y/?tag=kentcdodds-20"
        }}>{`AmazonBasics Mini DisplayPort to DisplayPort Display Cable - 3 Feet`}</a>{` -
I have two of these that connect my monitors to the dual DisplayPort adapter.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B007B9NV8Q/?tag=kentcdodds-20"
        }}>{`AmazonBasics AA Rechargeable Batteries`}</a>{` -
I use this for my wireless keyboard and trackpad.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00TOVTZ7K/?tag=kentcdodds-20"
        }}>{`AmazonBasics Ni-MH AA & AAA Battery Charger With USB Port for Rechargeable Batteries`}</a>{` -
How I recharge those batteries.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B016QO64FI/?tag=kentcdodds-20"
        }}>{`Apple Magic Keyboard`}</a>{` - This isn't
actually the one I have. The one I have is an older edition. But if I were
buying a new one, this is the one I'd buy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00AJE771W/?tag=kentcdodds-20"
        }}>{`Grifiti Slim Wrist Pad for Apple Wireless Keyboard`}</a>{` -
This goes in front of my keyboard and gives my wrists a little support.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B016QO5YWC/?tag=kentcdodds-20"
        }}>{`Apple Magic Trackpad 2`}</a>{` - This isn't
actually the one I have. The one I have is an older edition. But if I were
buying a new one, this is the one I'd buy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00AJCSIGM/?tag=kentcdodds-20"
        }}>{`Grifiti Slim Wrist Pad for Magic Trackpad`}</a>{` -
This goes under my trackpad and gives my wrist a little support.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00CD1PTF0/?tag=kentcdodds-20"
        }}>{`Bose Companion 2 Series III Multimedia Speakers`}</a>{` -
My computer speakers that sit on either side of my desk.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00OV41XAM/?tag=kentcdodds-20"
        }}>{`Mediabridge 3.5mm Male to Male Right Angle Stereo Audio Cable (8 Feet)`}</a>{` -
This connects my speakers to my dock`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00HVLUR86/?tag=kentcdodds-20"
        }}>{`Audio-Technica ATH-M50x Headphones`}</a>{` - I
don't use these often (the joys of a home office) but when I do, they're
fantastic.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01LRW956O/?tag=kentcdodds-20"
        }}>{`AUKEY Powered USB Hub`}</a>{` - I have more USB
ports than I know what to do with now. And it's great.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B003L20OZ0/?tag=kentcdodds-20"
        }}>{`Safco Products Vue Mesh Extended-Height Chair 3395BL`}</a>{` -
This is my chair. It's not terrific, but it's hard to find a chair that it's
both comfortable and suited for a standing desk. (You'll probably want this
`}<a parentName="li" {...{
          "href": "https://amazon.com/dp/B003L20OZU/?tag=kentcdodds-20"
        }}>{`Safco Products Loop Arms Set`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B000V4PPV4/?tag=kentcdodds-20"
        }}>{`Floortex Cleartex UltiMat Polycarbonate Chair Mat`}</a>{` -
My chair rolls around on this.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B005UA2WO2/?tag=kentcdodds-20"
        }}>{`Imprint CumulusPRO Commercial Standing Desk Anti-Fatigue Mat`}</a>{` -
When I'm standing I put this on top of the chair mat. Otherwise I slide it to
the side of my desk.`}</li>
    </ul>
    <h3 {...{
      "id": "camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera",
        "aria-label": "camera permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Camera`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00CMLX1O2/?tag=kentcdodds-20"
        }}>{`Arkon Camera Wall Mount`}</a>{` - To mount my
camera to the wall. This is really awesome.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00B20OYUO/?tag=kentcdodds-20"
        }}>{`Sony SEL-20F28 E-Mount 20mm F2.8 Prime Fixed Lens`}</a>{` -
A pancake lens with a nice balance of weight, size, aperture, and angle`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07VGB9BBH?tag=kentcdodds-20"
        }}>{`Sony Alpha a6400 Mirrorless Digital Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B003OBUJD0?tag=kentcdodds-20"
        }}>{`Sony ACPW20 AC Adaptor`}</a>{` - Constant power
for the camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01N5YEI0O?tag=kentcdodds-20"
        }}>{`Blackmagic Design Web Presenter`}</a>{` - To make
my Sony Camera work like a regular USB Webcam`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B000068OA4/?tag=kentcdodds-20"
        }}>{`Hosa PWC-143 IEC C13 to NEMA 5-15P Power Cord, 3 Feet`}</a>{` -
Power cable for the Blackmagic`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00NH11KIK?tag=kentcdodds-20"
        }}>{`AmazonBasics USB 2.0 Printer Cable - A-Male to B-Male Cord - 6 Feet`}</a>{` -
Connect the black magic to my computer`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B0060AU5MW?tag=kentcdodds-20"
        }}>{`BlueRigger Micro HDMI to HDMI Cable`}</a>{` - The
HDMI cable to connect the camera to the black magic thing`}</li>
    </ul>
    <h3 {...{
      "id": "lighting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lighting",
        "aria-label": "lighting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Lighting`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B013TSPWXO/?tag=kentcdodds-20"
        }}>{`Fotodiox Pro FlapJack Studio LED Light`}</a>{` -
The light that shines in my face`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B06XWSFX6D/?tag=kentcdodds-20"
        }}>{`Neewer Triangle Wall Mounting Boom Arm`}</a>{` -
This is what I have the light mounted on. I'm able to push the light out of
the way when I don't need it and I don't have anything cluttering the floor
making my room nice and clean. I love this.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B001D2GQ8Y/?tag=kentcdodds-20"
        }}>{`Manfrotto 156BLB 4-Feet Aluminum Mini Kit Stand with 015 Top (Black)`}</a>{` -
The stand I had for the light before I got the wall mount.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.projectorscreenstore.com/Projector-Screens/Draper-Silhouette-M-70-H-x-96-W-Manual-Pull-Down-Green-Screen-AV-Format-Chroma-Key-Green-V20207KG-79035.html"
        }}>{`Draper Silhouette M - 70" H x 96" W Manual Pull-Down Chroma Key Green Screen`}</a>{` -
This is my green screen that's mounted to the ceiling.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.flolight.com/bladelight-1/"
        }}>{`Bladelight (36")`}</a>{` - These light up
the green screen super well. They're mounted inside the ceiling.`}</li>
    </ul>
    <h3 {...{
      "id": "microphone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#microphone",
        "aria-label": "microphone permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Microphone`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B0002E4Z8M/?tag=kentcdodds-20"
        }}>{`Shure SM7B Cardioid Dynamic Microphone`}</a>{` -
This is a super nice microphone and gives fantastic quality. I see it in
professional studios all the time.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B004PJ414I/?tag=kentcdodds-20"
        }}>{`Heil Sound Microphone Boom`}</a>{` - This is
attached to my desk and reaches over my left monitor to get to my face.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B004MQSV04/?tag=kentcdodds-20"
        }}>{`Cloudlifter`}</a>{` - Boosts the power of my
passive microphone. It's silly easy to use.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B084BGC5LR?tag=kentcdodds-20"
        }}>{`Audient EVO 4 USB Audio Interface`}</a>{` - This
is the last place my microphone audio goes before it heads into my computer.
(I used to use this and it was pretty good, but a bit quiet:
`}<a parentName="li" {...{
          "href": "https://amazon.com/dp/B003CY6OHY/?tag=kentcdodds-20"
        }}>{`Pyle 2-Channel Audio Mixer`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B000ZLQ3Y6?tag=kentcdodds-20"
        }}>{`Audio-Technica Condenser Microphone (AT899)`}</a>{` -
I use this sometimes if I don't want a microphone in the shot when doing video
of my face.`}</li>
    </ul>
    <h3 {...{
      "id": "sound-proofing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sound-proofing",
        "aria-label": "sound proofing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Sound Proofing`}</h3>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/AwLj4qCyJ7E"
      }}>{`Watch me put this together`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07VDTR22R/?tag=kentcdodds-20"
        }}>{`50 Pack Acoustic Panels 1" X 12" X 12", Black`}</a>{` -
Attached to cut versions of yard signs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07S952JN9/?tag=kentcdodds-20"
        }}>{`10 Blank Signs White 18"x24"`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07MWRV574/?tag=kentcdodds-20"
        }}>{`Gorilla Glue`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B007RKFBT6/?tag=kentcdodds-20"
        }}>{`Command 4 lb White Picture Hanging Strips, Small`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.versare.com/shop/portable-partitions/acoustical-room-dividers/versifold-acoustical-room-divider.html"
        }}>{`VersiFold Acoustical Room Divider`}</a>{` -
I don't put this up very often, but I do have it in case I need a little extra
sound proofing or I want to do a video and not have any distractions behind
me.`}</li>
    </ul>
    <h2 {...{
      "id": "on-the-go",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#on-the-go",
        "aria-label": "on the go permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`On the Go`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01FFA730Y/?tag=kentcdodds-20"
        }}>{`KOPACK Lightweight Laptop Backpack`}</a>{` - I
love this backpack I bought another one as soon as the first was destroyed
`}<a parentName="li" {...{
          "href": "https://twitter.com/kentcdodds/status/1182010394300170240"
        }}>{`in a onewheel accident`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00X0GGLWM/?tag=kentcdodds-20"
        }}>{`Power Bank 20100mAh Portable Charger`}</a>{` -
This sits in my backpack and I can charge all my devices with it.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07DW4TFJP/?tag=kentcdodds-20"
        }}>{`USB C Hub Multiport Adapter: Ethernet, HDMI, USB-3 and USB-C`}</a>{` -
This stays in my backpack and I use it often when speaking at events or
visiting people's offices.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B000FPGP4U/?tag=kentcdodds-20"
        }}>{`Kensington Wireless Presenter with Red Laser Pointer`}</a>{` -
I've had this for years. I don't always use it, but when I need a laser
pointer/wireless presenter tool, I'm glad I have it!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00NH13I08/?tag=kentcdodds-20"
        }}>{`Mini DisplayPort (Thunderbolt) to VGA Adapter`}</a>{` -
Wish I didn't need to just keep this around, but I do.`}</li>
    </ul>
    <h2 {...{
      "id": "home-stuff",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-stuff",
        "aria-label": "home stuff permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Home Stuff`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07MSFFDNP/?tag=kentcdodds-20"
        }}>{`Yootech [2 Pack] Wireless Charger`}</a>{` - Where
I put my phone on my desk. The other one goes on my nightstand.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01L9O08PW/?tag=kentcdodds-20"
        }}>{`AmpliFi HD WiFi System by Ubiquiti Labs`}</a>{` -
This thing gives super fast internet speed, a huge range of connectivity, and
the range extenders are fantastic. I'll never bother with anything else again.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07NF9XDWG/?tag=kentcdodds-20"
        }}>{`Synology 5 bay NAS DiskStation DS1019+`}</a>{` -
I use this to store all of my pictures/movies/music/etc. It's amazing.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07R1P55GR/?tag=kentcdodds-20"
        }}>{`Seagate IronWolf 3.84TB NAS SSD Internal Solid State Drive`}</a>{` -
These are phenomenal SSDs for the NAS.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07D98DZ38/?tag=kentcdodds-20"
        }}>{`CORSAIR FORCE Series MP300 240GB NVMe PCIe M.2 SSD Solid State Storage`}</a>{` -
I bought two of these when I had the HDDs. Now that I have SSDs I'm not sure
they're necessary.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B07H241VK4/?tag=kentcdodds-20"
        }}>{`Seagate IronWolf 10TB NAS Internal Hard Drive HDD`}</a>{` -
The SSDs are `}<em parentName="li">{`super`}</em>{` expensive, so if you want a cheaper alternative (and more
space) get these. I swapped these for the SSDs because they make a little
mechanical clicking noise as the arms move around the disk and we could
literally hear it all over the house (we have pretty sensitive hearing) and it
was keeping us up at night. If you don't think that'll bother you or you have
a better sound-proofing strategy, then grab these.`}</li>
    </ul>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Other`}</h2>
    <ul>
      <li parentName="ul">{`For my window drapes: `}<a parentName="li" {...{
          "href": "https://amazon.com/dp/B00069C904/?tag=kentcdodds-20"
        }}>{`Bracket`}</a>{`,
`}<a parentName="li" {...{
          "href": "https://amazon.com/dp/B00PF72K38/?tag=kentcdodds-20"
        }}>{`Light Blocking Curtains`}</a>{`,
`}<a parentName="li" {...{
          "href": "https://amazon.com/dp/B001LTXRDG/?tag=kentcdodds-20"
        }}>{`Window Curtain Rod`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B01FCZ8I3I/?tag=kentcdodds-20"
        }}>{`Self Drilling Drywall Plastic Anchors with Screws Kit`}</a>{` -
I used this to mount several of the things on the walls and ceilings in my
office. They're solid, and you can screw them out if you make a mistake (which
I wish I could say I've never needed to do).`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amazon.com/dp/B00EC9RIDW/?tag=kentcdodds-20"
        }}>{`Double Gallery Picture Frame`}</a>{` - Great for
the kids to be able to decorate my office without coloring on the walls
(there's also a `}<a parentName="li" {...{
          "href": "https://amazon.com/dp/B00EC9RIKU/?tag=kentcdodds-20"
        }}>{`single frame`}</a>{`).`}</li>
    </ul>
    <h2 {...{
      "id": "old-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#old-setup",
        "aria-label": "old setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Old setup`}</h2>
    <p>{`To learn what this page looked like in the past, checkout
`}<a parentName="p" {...{
        "href": "https://github.com/koikar/aprenderalemania.com/commits/main/src/pages/uses.mdx"
      }}>{`the file history on GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      