import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import suzPhoto from "../../../../content/podcast/01/13-suz-hinton/suz-hinton.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  suzPhoto,
  React
};