import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import PhotoNader from "../../../../content/podcast/02/07-nader-dabit/nader-dabit.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  PhotoNader,
  React
};