import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import courtneyPhoto from "../../../../content/podcast/03/03-courtney-mccleve/courtney-mccleve.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  courtneyPhoto,
  React
};