import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hallo-ich-bin-aaron-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#hallo-ich-bin-aaron-",
        "aria-label": "hallo ich bin aaron  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Hallo! Ich bin Aaron 👋`}</h1>
    <p>{`Ich bin ein Softwareingenier, Designer und Deutschlehrer. Ich wurde in 1993 in
München geboren (du kannst dir mein Alter selber ausrechenen) und bin dort
aufgewachsen. Nachdem ich mein Abitur an der
`}<a parentName="p" {...{
        "href": "https://www.dsh.fi"
      }}>{`Deutschen Schule Helsinki`}</a>{` gemacht habe, bin ich nach
Holland gegangen um dort einen Bachelor Internationaler Studien an der
`}<a parentName="p" {...{
        "href": "https://www.universiteitleiden.nl/en"
      }}>{`Universität Leiden`}</a>{` zu machen.`}</p>
    <p>{`Obwohl ich eigentlich Poliker werden wollte, habe ich die Technologiewelt
gefunden und mich entschieden in die Startupwelt zu gehen. Mein aktuelles
Startup `}<a parentName="p" {...{
        "href": "https://karbook.com"
      }}>{`Karbook`}</a>{` hat Kunden in ganz Mexiko und vereinfacht
den Reparaturprozess von Autobesitzern und Autowerkstätten.`}</p>
    <p>{`Da ich in einer internationalen Familie aufgewachsen bin, waren Sprachen schon
immer ein wichtiger Teil meines Lebens. In 2020 habe ich angefangen neben meinem
Startup, Deutschlehrer zu werden. Plötzlich habe ich gemerkt, dass ich auch eine
Leidenschaft für Sprachen habe und vor allem sie zu lehrern.`}</p>
    <p><strong parentName="p">{`Jetzt bin ich ein Vollzeit Softwareentwickler und Deutschlehrer.`}</strong></p>
    <p>{`Meine Frau ist auch begeistert von diesem Projekt und sie übernimmt die
Videoproduktion und Qualitätskontrolle 💪.`}</p>
    <blockquote>
      <p parentName="blockquote">{`P.S. Wenn du meine Arbeit unterstützen willst, dann kannst du mir bald ganz
einfach ein Bier spendieren auf `}<a parentName="p" {...{
          "href": "https://patreon.com"
        }}>{`Patreon`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      