import * as React from 'react'
import aaron from '../images/aaron.png'

function BlogFooter() {
  return (
    <div style={{display: 'flex'}}>
      <div
        style={{
          paddingRight: 20,
        }}
      >
        <img
          src={aaron}
          alt="Aaron Koivunen"
          style={{
            maxWidth: 80,
            borderRadius: '50%',
          }}
        />
      </div>
      <p>
        <strong>Aaron Koivunen</strong>
        {` is a German teacher and software engineer. He helps people from all around the world to learn German in an easier and more efficient way. He currently lives in the city of Helsinki in Finland.`}
      </p>
    </div>
  )
}

export default BlogFooter
