import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-testing-garden-of-kent-c-dodds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-testing-garden-of-kent-c-dodds",
        "aria-label": "the testing garden of kent c dodds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`The Testing Garden of Kent C. Dodds`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Read
`}<a parentName="p" {...{
          "href": "https://joelhooks.com/digital-garden"
        }}>{`My blog is a digital garden, not a blog`}</a></p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/confidently-shipping-code"
        }}>{`Confidently Shipping Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/but-really-what-is-a-javascript-test"
        }}>{`But really, what is a JavaScript test?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/but-really-what-is-a-javascript-mock"
        }}>{`But really, what is a JavaScript mock?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/how-to-know-what-to-test"
        }}>{`How to know what to test`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/write-tests"
        }}>{`Write tests. Not too many. Mostly integration.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/unit-vs-integration-vs-e2e-tests"
        }}>{`Static vs Unit vs Integration vs E2E Testing for Frontend Apps`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/testing-implementation-details"
        }}>{`Testing Implementation Details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/react-hooks-whats-going-to-happen-to-my-tests"
        }}>{`React Hooks: What's going to happen to my tests?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/common-testing-mistakes"
        }}>{`Common Testing Mistakes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/why-i-never-use-shallow-rendering"
        }}>{`Why I Never Use Shallow Rendering`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/the-merits-of-mocking"
        }}>{`The Merits of Mocking`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/test-isolation-with-react"
        }}>{`Test Isolation with React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/react-is-an-implementation-detail"
        }}>{`React is an implementation detail`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/aha-testing"
        }}>{`AHA Testing 💡`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/effective-snapshot-testing"
        }}>{`Effective Snapshot Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/eliminate-an-entire-category-of-bugs-with-a-few-simple-tools"
        }}>{`Eliminate an entire category of bugs with a few simple tools`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      