import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "appearances",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#appearances",
        "aria-label": "appearances permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Appearances`}</h1>
    <h2 {...{
      "id": "podcasts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#podcasts",
        "aria-label": "podcasts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Podcasts`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://spec.fm/podcasts/developer-tea"
        }}>{`Developer Tea`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://spec.fm/podcasts/developer-tea/RAHNGgoH"
        }}>{`Teaching in Public w/ Kent C. Dodds (part 2)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://spec.fm/podcasts/developer-tea"
        }}>{`Developer Tea`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://spec.fm/podcasts/developer-tea/X5XzqN7P"
        }}>{`Teaching in Public w/ Kent C. Dodds (part 1)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.software-engineering-unlocked.com/"
        }}>{`Software Engineering Unlocked`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.software-engineering-unlocked.com/no-mocks-allowed/"
        }}>{`Episode 29: No mocks allowed – A testing discussion with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://develomentor.com/"
        }}>{`Developmentor`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://develomentor.com/2020/10/15/kent-c-dodds-software-quality-instructor-and-world-renowned-speaker-100/"
        }}>{`Kent C. Dodds – Software Quality Instructor and World Renowned Speaker #100`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.frontend.social/catchup"
        }}>{`Frontend Social`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.frontend.social/catchup/with-kent"
        }}>{`Catch up with Kent!`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://egghead.io/podcasts?af=5236ad"
        }}>{`Egghead.io Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://egghead.io/podcasts/kent-c-dodds-chats-about-how-epic-react-was-designed-for-learner-success?af=5236ad"
        }}>{`Kent C. Dodds Chats About How Epic React was Designed for Learner Success`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactpodcast.com"
        }}>{`React Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://reactpodcast.com/112"
        }}>{`112: Kent C. Dodds on Epic React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://epicreact.dev/podcast"
        }}>{`EpicReact.Dev Podcast`}</a>{`: ... literally every
episode 😅`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.software-engineering-unlocked.com/"
        }}>{`Software Engineering Unlocked`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.software-engineering-unlocked.com/double-down-integration-tests-kent-dodds/"
        }}>{`Episode 24: Why you should double down on integration tests with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kentcdodds.com/podcast"
        }}>{`Chats with Kent Podcast`}</a>{`: ... literally every
episode 😅`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ecpodcast.io/"
        }}>{`The Entrepreneurial Coder Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.ecpodcast.io/episodes/19-kent-c-dodds-how-to-replace-your-salary-by-creating-content"
        }}>{`#19 - Kent C. Dodds - How To Replace Your Salary by Creating Content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.blog/podcast/"
        }}>{`The Stack Overflow Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://stackoverflow.blog/2019/11/19/podcast-a-few-of-our-favorite-things-about-react/"
        }}>{`Podcast: A Few Of Our Favorite Things About React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLnTRniWXnjf9b4bVmkwNAtk1Cpna0uDNb"
        }}>{`Better Coding Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=4wRFXaLL4fA&list=PLnTRniWXnjf9b4bVmkwNAtk1Cpna0uDNb"
        }}>{`Kent C. Dodds: Managing a Business and Contributing to Open Source | Better Coding Podcast #7`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://anchor.fm/codecareer"
        }}>{`CodeCareer.io Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://anchor.fm/codecareer/episodes/EP6-Kent-C-Dodds---Javascript--teaching--and-personal-missions-e4rmum"
        }}>{`EP6 Kent C Dodds - Javascript, teaching, and personal missions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ducktapes.fm/"
        }}>{`Duck Tapes`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://anchor.fm/ducktapes/episodes/Kent-C--Dodds-Mix-e4k0ur"
        }}>{`Kent C. Dodds Mix`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCk13Ucc26mWqI4xvsbO13jw"
        }}>{`Applitools: Testing Tips & Trips Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/4zmyWJ8gc6U"
        }}>{`Season 1, Episode 03`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactpodcast.com"
        }}>{`React Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://reactpodcast.com/47"
        }}>{`47: Develop Your Career with Kent C Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://devchat.tv/react-round-up"
        }}>{`React RoundUp`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://devchat.tv/react-round-up/rru-043-testing-react-apps-without-testing-implementation-details-with-kent-c-dodds"
        }}>{`RRU 043: Testing React Apps Without Testing Implementation Details with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactpodcast.com"
        }}>{`React Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://reactpodcast.com/21"
        }}>{`21: Delete Your Components with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ageekleader.com"
        }}>{`A Geek Leader Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.ageekleader.com/agl-059-kent-c-dodds"
        }}>{`AGL 059: Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.thisdot.co/modern-web"
        }}>{`Modern Web: The Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/nw-GFMW8LSQ"
        }}>{`S05E12 Modern Web Podcast - Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://devchat.tv/react-round-up"
        }}>{`React Round Up`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://devchat.tv/react-round-up/rru-003-advanced-component-patterns-and-downshift-with-kent-c-dodds"
        }}>{`Advanced Component Patterns and Downshift with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://joecolantonio.com/testtalks"
        }}>{`TestTalks`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://joecolantonio.com/testtalks/195-javascript-testing-using-jest-with-kent-c-dodds"
        }}>{`JavaScript Testing Using Jest with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCDHuR3rK_PqaTuYH4qGN3fg"
        }}>{`newsbundle.js`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/_rFd01gvatQ"
        }}>{`Future of React explained; #03.1`}</a>{` and
`}<a parentName="li" {...{
          "href": "https://youtu.be/aRFBu0k8kIk?t=217s&list=PLV5CVI1eNcJi8sor_aQ2AzOeQ3On3suOr"
        }}>{`Future of React with Kent C. Dodds; #03.2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCIGqC7zgqiFrGztcgXSSWOg"
        }}>{`Workshop.me`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/deWkGD7ylcw?index=1&list=PLV5CVI1eNcJi8sor_aQ2AzOeQ3On3suOr"
        }}>{`E1 - Kent C. Dodds, Testing, React, TC39`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://fixate.it"
        }}>{`Fixate on Code`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://fixate.it/podcast/consume-build-and-teach-kent-c-dodds"
        }}>{`16 - Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://devchat.tv/adv-in-angular"
        }}>{`Adventures in Angular`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://devchat.tv/adv-in-angular/mas-020-kent-c-dodds"
        }}>{`My Angular Story 020: Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.orbit.fm/weboftomorrow"
        }}>{`Web of Tomorrow`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.orbit.fm/weboftomorrow/44"
        }}>{`44: How to Jumpstart Your Career - Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.fullstackradio.com"
        }}>{`Full Stack Radio`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://www.fullstackradio.com/79"
        }}>{`79: Kent C. Dodds - Building Reusable React Components with Render Props`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLZ66c9_z3umOuPSGsTu3mfzt6PGZeUyQZ"
        }}>{`#!hashbang`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/ltzNIOF_L3E"
        }}>{`HashBang Episode 5: Assert(js) panel: Kent C. Dodds, Justin Searls, Gleb Bahmutov and Brian Mann`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.heavybit.com/library/podcasts/jamstack-radio"
        }}>{`JAMStack Radio`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.heavybit.com/library/podcasts/jamstack-radio/ep-23-introduction-to-downshift-and-glamorous/?utm_campaign=coschedule&utm_source=twitter&utm_medium=heavybit&utm_content=Ep.%20%2323,%20Introduction%20to%20Downshift%20and%20Glamorous"
        }}>{`Ep. #23, Introduction to Downshift and Glamorous`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://itcareerenergizer.com"
        }}>{`I.T. Career Energizer`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://itcareerenergizer.com/e35"
        }}>{`Episode 35 - Learn, Build, and Teach with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL1XPwt2TrDrxWL3ohvospBDFP3GMeXgQE"
        }}>{`NADCAST`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/FEeL_c2wtpU"
        }}>{`Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tylermcginnis.com/podcast"
        }}>{`Tyler McGinnis Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://tylermcginnis.com/podcast/kentcdodds"
        }}>{`Work/Life Balance, Education, and Open Source with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://developeronfire.com"
        }}>{`Developer on Fire`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://developeronfire.com/podcast/episode-239-kent-c-dodds-nice-and-inclusive"
        }}>{`Episode 239 | Kent C. Dodds - Nice and Inclusive`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://changelog.com"
        }}>{`The Changelog`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://changelog.com/podcast/246"
        }}>{`First-time Contributors and Maintainer Balance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.dadsindev.com"
        }}>{`Dads in Development`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://www.dadsindev.com/12"
        }}>{`Episode 12: CSS Modules`}</a>{` (and CSS in JS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://careerjs.com"
        }}>{`Career.js`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://careerjs.com/#/3"
        }}>{`Ep. 3 - How to Level Up Your Career with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://developingstory.netlify.com"
        }}>{`This Developing Story`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://developingstory.netlify.com/tds-56-kent-c-dodds"
        }}>{`Ep. 56 - Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://devchat.tv/adv-in-angular"
        }}>{`Adventures in Angular`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://devchat.tv/adv-in-angular/022-aia-form-validation-with-kent-c-dodds"
        }}>{`022 AiA Form Validation with Kent C. Dodds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://thewebplatformpodcast.com"
        }}>{`The Web Platform Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://thewebplatformpodcast.com/72-teaching-and-learning-angular"
        }}>{`Teaching and Learning Angular`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.modern-web.org"
        }}>{`Modern Web Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://modernweb.podbean.com/e/s01e03-es6-beard-of-jeff-cross-air-squats-and-javascript-air-with-kent-c-dodds-and-ben-lesh"
        }}>{`S01E03 - ES6, Beard of Jeff Cross, Air Squats, and JavaScript Air with Kent C. Dodds and Ben Lesh`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webbehind.com"
        }}>{`The Web Behind`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://webbehind.com/episode-2-kent-c-dodds"
        }}>{`Kent C. Dodds`}</a></li>
    </ul>
    <h2 {...{
      "id": "interviews--chats",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interviews--chats",
        "aria-label": "interviews  chats permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Interviews & Chats`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtu.be/lD5IhYNIKvk?list=PL-G5r6j4GptGUKhgD7uBEovvINVFMYzTF"
        }}>{`This.JavaScript`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/iycQpCFBASE"
        }}>{`This.JavaScript: State of Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/user/OreillyMedia"
        }}>{`O'Reilly Media`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/7O2dpgNoY4M"
        }}>{`Interview with Kent C. Dodds (O'Reilly Fluent Conference 2016)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.modern-web.org"
        }}>{`Modern Web Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/rMbL1w-Bby4"
        }}>{`O'Reilly FluentConf 2016 - JavaScript 2016 and The "Good 'Ol Days" of the Internet (Part 2 of 2)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.modern-web.org"
        }}>{`Modern Web Podcast`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://youtu.be/QRt9QBdpQQ0"
        }}>{`O'Reilly FluentConf 2016 - @getify love, best talks, conferences, wizard hats, and pajamas (Part 1 of 2)`}</a></li>
    </ul>
    <h2 {...{
      "id": "blogposts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blogposts",
        "aria-label": "blogposts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Blogposts`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marriottschool.byu.edu/"
        }}>{`Marriott School of Business`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://marriottschool.byu.edu/news/article?id=1871"
        }}>{`An Epic Impact`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.paysa.com/blog"
        }}>{`paysa blog`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://www.paysa.com/blog/2017/08/31/expert-roundup-coding-bootcamps"
        }}>{`Expert Roundup: Coding Bootcamps`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.onalytica.com/blog"
        }}>{`onalytica Influencer Library`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://www.onalytica.com/blog/posts/digital-inclusion-accessibility-top-100-influencers-brands"
        }}>{`Digital Inclusion and Accessibility: Top 100 Influencers and Brands`}</a>{`
(I'm #29)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://techbeacon.com"
        }}>{`techbeacon.com`}</a>{`:
`}<a parentName="li" {...{
          "href": "http://techbeacon.com/javascript-leaders-you-should-follow-twitter"
        }}>{`41 JavaScript experts to follow on Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://opensource.com"
        }}>{`opensource.com`}</a>{`:
`}<a parentName="li" {...{
          "href": "https://opensource.com/business/16/10/all-things-open-interview-kent-c-dodds"
        }}>{`Tips from a software engineer for a balanced life`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      