import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import scottPhoto from "../../../../content/podcast/01/11-scott-hanselman/scott-hanselman.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  scottPhoto,
  React
};