import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import saronPhoto from "../../../../content/podcast/03/10-saron-yitbarek/saron-yitbarek.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  saronPhoto,
  React
};