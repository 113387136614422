import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import ericPhoto from "../../../../content/podcast/01/07-eric-berry/eric-berry.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  ericPhoto,
  React
};