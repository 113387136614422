import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import davidPhoto from "../../../../content/podcast/01/05-david-khourshid/david-khourshid.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  davidPhoto,
  React
};