import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import biancaPhoto from "../../../../content/podcast/02/02-bianca-gandolfo/bianca-gandolfo.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  biancaPhoto,
  React
};