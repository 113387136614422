import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import emmaPhoto from "../../../../content/podcast/01/06-emma-bostian/emma-bostian.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  emmaPhoto,
  React
};