import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import ameliaPhoto from "../../../../content/podcast/03/02-amelia-wattenberger/amelia-wattenberger.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  ameliaPhoto,
  React
};