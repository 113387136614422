import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kcd-learning-clubs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kcd-learning-clubs",
        "aria-label": "kcd learning clubs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`KCD Learning Clubs`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "430px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/39edf9a49f8568c9af92c7726ab5a14d/40c27/learning.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.67567567567568%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAEackYukeNf/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQMCERMAIv/aAAgBAQABBQJbJ5Fk60FJh4KqAgT3/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESUf/aAAgBAwEBPwGnhR//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARP/2gAIAQIBAT8BZx//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIREiExUYH/2gAIAQEABj8C4TjdMWOIrOjJS0aUvJH/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhQTFx/9oACAEBAAE/IXpiCpcXyPaeGQo2nMAIO229xZI+Moa14Bn/2gAMAwEAAgADAAAAEGDv/8QAGhEAAgIDAAAAAAAAAAAAAAAAABEBITFBwf/aAAgBAwEBPxBDb42TB1w//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxB14Qhrf//EABsQAQEAAwEBAQAAAAAAAAAAAAERACExYUHw/9oACAEBAAE/ECrQVJuxn7uIIpoEkNauCAkCo1nBfubXk+YCBHTTr3LdGhaKHBHP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "I freaking love learning",
            "title": "I freaking love learning",
            "src": "/static/39edf9a49f8568c9af92c7726ab5a14d/40c27/learning.jpg",
            "srcSet": ["/static/39edf9a49f8568c9af92c7726ab5a14d/8356d/learning.jpg 259w", "/static/39edf9a49f8568c9af92c7726ab5a14d/40c27/learning.jpg 430w"],
            "sizes": "(max-width: 430px) 100vw, 430px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A `}<strong parentName="p">{`KCD Learning Club`}</strong>{` is a group of up to 10 people who are going through
`}<a parentName="p" {...{
        "href": "/courses"
      }}>{`my coursework`}</a>{` (or anything else) together. Research suggests that we
learn better when we learn together, so that's the goal of a KCD Learning Club:
it's a place for a group of people who want to learn the same thing to do so
together.`}</p>
    <h2 {...{
      "id": "why-join-a-learning-club",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-join-a-learning-club",
        "aria-label": "why join a learning club permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Why join a learning club?`}</h2>
    <ol>
      <li parentName="ol">{`You'll learn what you want to learn better than if you try on your own.`}</li>
      <li parentName="ol">{`Develop friendships with other nice learners.`}</li>
      <li parentName="ol">{`You'll get a special place to meet virtually (both async text and sync video)
in a
`}<a parentName="li" {...{
          "href": "https://support.discord.com/hc/en-us/articles/223657667-Group-Chat-and-Calls"
        }}>{`Discord Group DM`}</a>{`.`}</li>
      <li parentName="ol">{`You can chat with members of the `}<a parentName="li" {...{
          "href": "/discord"
        }}>{`KCD Community on Discord`}</a>{`, some
of whom may also be part of learning clubs similar to your own!`}</li>
      <li parentName="ol">{`You have access to me (Kent) during twice-weekly
`}<a parentName="li" {...{
          "href": "/office-hours"
        }}>{`office hours`}</a>{` to ask questions.`}</li>
    </ol>
    <h2 {...{
      "id": "joining-or-starting-a-learning-club",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#joining-or-starting-a-learning-club",
        "aria-label": "joining or starting a learning club permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Joining or starting a learning club`}</h2>
    <p>{`If you'd like to start a club, then
`}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://youtu.be/eMx5ZCkIwfQ"
        }}>{`follow along with this video 📺`}</a></strong></p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/eMx5ZCkIwfQ?rel=0",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <p>{`Here's the gist:`}</p>
    <ol>
      <li parentName="ol">{`Identify the curriculum you want to go through
(`}<a parentName="li" {...{
          "href": "https://testingjavascript.com"
        }}>{`TestingJavaScript.com`}</a>{`,
`}<a parentName="li" {...{
          "href": "https://epicreact.dev"
        }}>{`EpicReact.Dev`}</a>{`, etc.)`}</li>
      <li parentName="ol">{`Join the `}<a parentName="li" {...{
          "href": "/discord"
        }}>{`KCD Community on Discord`}</a></li>
      <li parentName="ol">{`Check the `}<inlineCode parentName="li">{`#📝-open-clubs`}</inlineCode>{` channel to see whether there's a club accepting
new members that:`}
        <ul parentName="li">
          <li parentName="ul">{`is going through the material you want to learn`}</li>
          <li parentName="ul">{`is meeting at a time that works for your schedule`}</li>
        </ul>
      </li>
    </ol>
    <p>{`If there is a club available, then join that one by filling out the registration
form for the club. Otherwise, you can make your own by following these steps:`}</p>
    <blockquote>
      <p parentName="blockquote">{`NOTE: You might be able to use a schedule template from the
`}<a parentName="p" {...{
          "href": "https://github.com/kentcdodds/kcd-learning-clubs-ideas"
        }}>{`📍 KCD Learning Clubs Curriculum Ideas and Templates`}</a>{`
repo.`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">{`Create your registration form. You can find an example and template to copy
in `}<a parentName="li" {...{
          "href": "https://kcd.im/kcd-learning-club-docs"
        }}>{`this Google Drive Folder`}</a>{`.`}</li>
      <li parentName="ol">{`In the KCD Community on Discord, go to the `}<inlineCode parentName="li">{`#🤖-talk-to-bots`}</inlineCode>{` channel and say
"`}<inlineCode parentName="li">{`?clubs create LINK_TO_GOOGLE_FORM`}</inlineCode>{`". The bot will verify your form has all
the right information in it. Please fix anything that's missing or misplaced.`}</li>
      <li parentName="ol">{`The bot will add your club to the `}<inlineCode parentName="li">{`#📝-open-clubs`}</inlineCode>{` channel.`}</li>
      <li parentName="ol">{`Wait for the friend invites to come in from people joining your club.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://support.discord.com/hc/en-us/articles/223657667-Group-Chat-and-Calls"
        }}>{`Create a Group DM`}</a>{`
with the people who filled out your form.`}</li>
      <li parentName="ol">{`When your group is full or you're ready to start, react to the
`}<inlineCode parentName="li">{`#📝-open-clubs`}</inlineCode>{` message with a 🏁 and the bot will delete it (so you don't
get additional registrations).`}</li>
    </ol>
    <h2 {...{
      "id": "learning-club-captain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-club-captain",
        "aria-label": "learning club captain permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Learning Club Captain`}</h2>
    <p>{`The person who creates the group is the Learning Club Captain. They are
responsible for putting together a schedule for when and how the club will
interact and what activities they will participate in to learn together. They're
responsible for leading the club meetings and ensuring the club is accomplishing
its learning goals.`}</p>
    <p>{`This role `}<em parentName="p">{`can`}</em>{` rotate among the members if that's what you all want to do.`}</p>
    <h2 {...{
      "id": "learning-club-meetings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-club-meetings",
        "aria-label": "learning club meetings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Learning Club Meetings`}</h2>
    <p>{`Here's an example meeting agenda:`}</p>
    <ol>
      <li parentName="ol">{`Greetings`}</li>
      <li parentName="ol">{`Review deliverables from the previous meeting`}</li>
      <li parentName="ol">{`Discuss any relevant learnings since the last meeting`}</li>
      <li parentName="ol">{`Participate in a "learning activity"`}</li>
      <li parentName="ol">{`Discuss any learnings and ask/answer questions`}</li>
      <li parentName="ol">{`Determine the deliverables for the next meeting`}</li>
    </ol>
    <p>{`Someone should make notes of any group questions to take to the next KCD Office
Hours.`}</p>
    <blockquote>
      <p parentName="blockquote">{`While meeting synchronously over video is preferred, it's not always possible
for everyone to meet synchronously due to timezone differences. A club can
agree to conduct all meetings asynchronously in the text chat if they must.`}</p>
    </blockquote>
    <h3 {...{
      "id": "learning-activities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#learning-activities",
        "aria-label": "learning activities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Learning Activities`}</h3>
    <p>{`The learning activity can happen as an activity you do together during the
meeting, or as something that everyone is expected to do before the meeting.`}</p>
    <p>{`Examples of activities:`}</p>
    <ul>
      <li parentName="ul">{`Watch a video`}</li>
      <li parentName="ul">{`Read a blog post`}</li>
      <li parentName="ul">{`Build a demo`}</li>
    </ul>
    <p>{`These should expose people to new ideas or think critically about concepts their
already familiar with.`}</p>
    <h3 {...{
      "id": "learning-discussion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#learning-discussion",
        "aria-label": "learning discussion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Learning Discussion`}</h3>
    <p>{`The core of the club meeting is discussion of the learning activity. This is the
opportunity for everyone to ask questions and provide answers about the new
ideas and concepts. It will allow people the opportunity to think critically
about their understanding of the material.`}</p>
    <h2 {...{
      "id": "learning-club-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-club-schedule",
        "aria-label": "learning club schedule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Learning Club Schedule`}</h2>
    <p>{`The schedule is a simple list of the order of concepts you'll be learning. If
you're planning to go through `}<a parentName="p" {...{
        "href": "/courses"
      }}>{`one of my courses`}</a>{`, then it can simply
be a list of the videos with a date associated. It could also be a series of
blog posts you want to read and discuss together. In that case it would be a
list of the posts you want to discuss.`}</p>
    <p>{`The schedule doesn't have to be set in stone (it can be flexible and change over
time based on the needs of the club). It's important that it's scheduled ahead
of time so people signing up know when the meetings will be.`}</p>
    <h3 {...{
      "id": "example-schedules",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-schedules",
        "aria-label": "example schedules permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Example Schedules`}</h3>
    <h4 {...{
      "id": "meeting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#meeting",
        "aria-label": "meeting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Meeting`}</h4>
    <p>{`For meetings, please specify the time (with the timezone 🕰) for the meeting as
well as the topic:`}</p>
    <pre><code parentName="pre" {...{}}>{`Weekly on Mondays at 3:30 PM MST:

1. Sept. 14th: Fundamentals of Testing in JavaScript
2. Sept. 21st: JavaScript Mocking Fundamentals
3. Sept. 28th: Configure Jest for Testing JavaScript Applications
4. Oct. 5th: Test React Components with Jest and React Testing Library
5. Oct. 12th: Install, Configure, and Script Cypress for JavaScript Web Applications
6. Oct. 19th: Use DOM Testing Library to test any JS framework
7. Oct. 26th: Test Node.js Backends
`}</code></pre>
    <p>{`These can also be more flexible. You don't have to do weekly meetings:`}</p>
    <pre><code parentName="pre" {...{}}>{`Mondays and Thursdays at 3:30 PM MST:

1. Sept. 14th: Fundamentals of Testing in JavaScript
2. Sept. 17th: JavaScript Mocking Fundamentals
3. Sept. 21st: Configure Jest for Testing JavaScript Applications
4. Sept. 24th: Test React Components with Jest and React Testing Library
5. Sept. 28th: Install, Configure, and Script Cypress for JavaScript Web Applications
6. Oct. 1st: Use DOM Testing Library to test any JS framework
7. Oct. 5th: Test Node.js Backends
`}</code></pre>
    <p>{`Or it could be totally random. It's entirely up to you as the club captain.`}</p>
    <h4 {...{
      "id": "async-learning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#async-learning",
        "aria-label": "async learning permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Async Learning`}</h4>
    <p>{`If you'd rather just keep the discussion asynchronous (via the text chat in your
group DM), then that's awesome too, the schedule could be something more like
this:`}</p>
    <pre><code parentName="pre" {...{}}>{`1. Sept. 14th: Finish "Fundamentals of Testing in JavaScript"
2. Sept. 17th: Finish "JavaScript Mocking Fundamentals"
3. Sept. 21st: Finish "Configure Jest for Testing JavaScript Applications"
4. Sept. 24th: Finish "Test React Components with Jest and React Testing Library"
5. Sept. 28th: Finish "Install, Configure, and Script Cypress for JavaScript Web Applications"
6. Oct. 1st: Finish "Use DOM Testing Library to test any JS framework"
7. Oct. 5th: Finish "Test Node.js Backends"
`}</code></pre>
    <p>{`In this case the schedule is to help keep everyone accountable and on the same
page, but you don't need to specify an actual time or timezone because there's
no actual meeting time. Everyone just knows they need to have that part of the
curriculum done by that day so they can participate in the same async discussion
about the same material with everyone else.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      