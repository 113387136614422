import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import PhotoJenn from "../../../../content/podcast/02/03-jenn-creighton/jenn-creighton.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  PhotoJenn,
  React
};