import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
import croppedPhoto from '../images/hero/aaron.png';
import uncroppedPhoto from '../images/photo.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "kents-name",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#kents-name",
        "aria-label": "kents name permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Kent's Name`}</h2>
    <p>{`These are correct:`}</p>
    <ul>
      <li parentName="ul">{`Kent`}</li>
      <li parentName="ul">{`Kent C.`}</li>
      <li parentName="ul">{`Kent C. Dodds`}</li>
      <li parentName="ul">{`Dodds`}</li>
    </ul>
    <p>{`This is `}<em parentName="p"><strong parentName="em">{`not`}</strong></em>{` correct:`}</p>
    <ul>
      <li parentName="ul">{`Kent Dodds`}</li>
      <li parentName="ul">{`Kent Dodd`}</li>
      <li parentName="ul">{`Kent Dobbs`}</li>
      <li parentName="ul">{`Kent C. Dobbs`}</li>
      <li parentName="ul">{`Kent C Dodds (missing the `}<inlineCode parentName="li">{`.`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Kent Dogs`}</li>
    </ul>
    <p>{`And if you want to add a possesive apostrophe to "Dodds" then it should be:
`}<inlineCode parentName="p">{`Dodds'`}</inlineCode>{` not `}<inlineCode parentName="p">{`Dodd's`}</inlineCode>{` nor `}<inlineCode parentName="p">{`Dodds's`}</inlineCode>{`.
`}<a parentName="p" {...{
        "href": "https://www.grammarbook.com/punctuation/apostro.asp"
      }}>{`Learn more`}</a></p>
    <h2 {...{
      "id": "photos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#photos",
        "aria-label": "photos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Photos`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Right click to save the one you want`}</p>
    </blockquote>

    <div style={{
      display: 'flex',
      justifyContent: 'space-around',
      textAlign: 'center'
    }}>
  <div>
    <img alt="Cropped photo of Kent" style={{
          maxHeight: 200,
          display: 'block',
          marginBottom: 4
        }} src={croppedPhoto} />
    <small>Cropped photo of Kent</small>
  </div>
  <div>
    <img alt="Unropped photo of Kent" style={{
          maxHeight: 200,
          display: 'block',
          marginBottom: 4
        }} src={uncroppedPhoto} />
    <small>Uncropped photo of Kent</small>
  </div>
    </div>
    <h2 {...{
      "id": "bio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bio",
        "aria-label": "bio permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Bio`}</h2>
    <h3 {...{
      "id": "long-first-person",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#long-first-person",
        "aria-label": "long first person permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Long First Person`}</h3>
    <p>{`Hi, I'm Kent C. Dodds. I'm a world renowned speaker, teacher, and trainer and
I'm actively involved in the
`}<a parentName="p" {...{
        "href": "https://github.com/kentcdodds"
      }}>{`open source community`}</a>{` as a maintainer and
contributor of hundreds of popular npm packages. I am the creator of
`}<a parentName="p" {...{
        "href": "https://epicreact.dev"
      }}>{`EpicReact.Dev`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://testingjavascript.com"
      }}>{`TestingJavaScript.com`}</a>{`. I'm an instructor on
`}<a parentName="p" {...{
        "href": "https://egghead.io/instructors/kentcdodds?af=5236ad"
      }}>{`egghead.io`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://frontendmasters.com"
      }}>{`Frontend Masters`}</a>{`. I'm also a
`}<a parentName="p" {...{
        "href": "https://developers.google.com/experts/people/kent-c-dodds"
      }}>{`Google Developer Expert`}</a>{`.
I am happily married and the father of four kids. I like my family, code,
JavaScript, and React.`}</p>
    <h3 {...{
      "id": "long-third-person",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#long-third-person",
        "aria-label": "long third person permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Long Third Person`}</h3>
    <p>{`Kent C. Dodds is a world renowned speaker, teacher, and trainer and he's
actively involved in the `}<a parentName="p" {...{
        "href": "https://github.com/kentcdodds"
      }}>{`open source community`}</a>{`
as a maintainer and contributor of hundreds of popular npm packages. Kent is the
creator of `}<a parentName="p" {...{
        "href": "https://epicreact.dev"
      }}>{`EpicReact.Dev`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://testingjavascript.com"
      }}>{`TestingJavaScript.com`}</a>{`. He's an instructor on
`}<a parentName="p" {...{
        "href": "https://egghead.io/instructors/kentcdodds?af=5236ad"
      }}>{`egghead.io`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://frontendmasters.com"
      }}>{`Frontend Masters`}</a>{`. He's also a
`}<a parentName="p" {...{
        "href": "https://developers.google.com/experts/people/kent-c-dodds"
      }}>{`Google Developer Expert`}</a>{`.
Kent is happily married and the father of four kids. He likes his family, code,
JavaScript, and React.`}</p>
    <h3 {...{
      "id": "short-first-person",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#short-first-person",
        "aria-label": "short first person permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Short First Person`}</h3>
    <p>{`My name is Kent C. Dodds and I'm a JavaScript engineer and teacher. I'm also
active in the open source community. I like my family, JavaScript, and React.`}</p>
    <h3 {...{
      "id": "short-third-person",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#short-third-person",
        "aria-label": "short third person permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Short Third Person`}</h3>
    <p>{`Kent C. Dodds is a JavaScript engineer and teacher. He's also active in the open
source community. He likes his family, JavaScript, and React.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      