import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This code of conduct applies to all community sites, events, and workshops
(hereafter referred to simply as "events") run and managed by the Kent C. Dodds
team.`}</p>
    <p>{`In addition to these rules of conduct, everyone participating in events is
encouraged to abide by the
`}<a parentName="p" {...{
        "href": "https://www.recurse.com/social-rules"
      }}>{`Recurse Center Social Rules`}</a>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.recurse.com/social-rules#no-well-actuallys"
        }}>{`No well-actually's`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.recurse.com/social-rules#no-feigning-surprise"
        }}>{`No feigning surprise`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.recurse.com/social-rules#no-backseat-driving"
        }}>{`No backseat driving`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.recurse.com/social-rules#no-subtle-isms"
        }}>{`No subtle -isms`}</a></li>
    </ul>
    <h1 {...{
      "id": "code-of-conduct",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code-of-conduct",
        "aria-label": "code of conduct permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Code of Conduct`}</h1>
    <p>{`All attendees, speakers, instructors, and volunteers at our event are required
to agree with the following code of conduct. Organizers will enforce this code
throughout the event. We expect cooperation from all participants to help ensure
a safe environment for everybody.`}</p>
    <h2 {...{
      "id": "have-questionsneed-to-report-an-issue",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#have-questionsneed-to-report-an-issue",
        "aria-label": "have questionsneed to report an issue permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Have questions/need to report an issue?`}</h2>
    <p>{`Email us at
`}<a parentName="p" {...{
        "href": "mailto:team@kentcdodds.com?subject=Regarding%20the%20Code%20of%20Conduct"
      }}>{`team@kentcdodds.com`}</a></p>
    <h2 {...{
      "id": "the-quick-version",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-quick-version",
        "aria-label": "the quick version permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`The Quick Version`}</h2>
    <p>{`Our event is dedicated to providing a harassment-free event experience for
everyone, regardless of gender, gender identity and expression, age, sexual
orientation, disability, physical appearance, body size, race, ethnicity,
religion (or lack thereof), or technology choices. We do not tolerate harassment
of event participants in any form. Sexual language and imagery is not
appropriate for any event venue, including presentations, comments, questions,
in chat, Twitter and other online media. Event participants violating these
rules may be sanctioned or expelled from the event `}<em parentName="p">{`without a refund`}</em>{` at the
discretion of the event organizers.`}</p>
    <h2 {...{
      "id": "the-less-quick-version",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-less-quick-version",
        "aria-label": "the less quick version permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`The Less Quick Version`}</h2>
    <p>{`Harassment includes offensive verbal comments related to gender, gender identity
and expression, age, sexual orientation, disability, physical appearance, body
size, race, ethnicity, religion, technology choices, sexual images in public
spaces, deliberate intimidation, stalking, following, harassing photography or
recording, sustained disruption of talks or other events, inappropriate physical
contact, and unwelcome sexual attention.`}</p>
    <p>{`Participants asked to stop any harassing behavior are expected to comply
immediately.`}</p>
    <p>{`If a participant engages in harassing behavior, the event organizers may take
any action they deem appropriate, including warning the offender or expulsion
from the event with no refund.`}</p>
    <p>{`If you are being harassed, notice that someone else is being harassed, or have
any other concerns, please contact a member of event staff immediately.`}</p>
    <p>{`Event staff will be happy to help participants contact venue security or local
law enforcement, provide escorts, or otherwise assist those experiencing
harassment to feel safe for the duration of the event. We value your attendance.`}</p>
    <p>{`We expect participants to follow these rules at event and all venues and
event-related social events.`}</p>
    <hr></hr>
    <p>{`This Code of Conduct is adapted from
`}<a parentName="p" {...{
        "href": "https://confcodeofconduct.com"
      }}>{`Conference Code of Conduct`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      