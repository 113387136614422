import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#links",
        "aria-label": "links permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Links`}</h1>
    <p>{`Links to some things about me...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://kentcdodds.com"
        }}>{`Home`}</a>{` - This website :)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://kentcdodds.com/links"
        }}>{`Links`}</a>{` - This page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/news"
        }}>{`Kent C. Dodds Mail`}</a>{` - My newsletter!! 💌`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/ama"
        }}>{`AMA`}</a>{` - Ask Me Anything`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/blog"
        }}>{`Blog`}</a>{` - My various musings about JavaScript and software
development`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/twitter"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/github"
        }}>{`GitHub`}</a>{` - I have a bunch of projects on here :)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/npm"
        }}>{`npm`}</a>{` - My published open source JavaScript modules`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/info"
        }}>{`Info`}</a>{` - Bio and Photo`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://javascriptair.com"
        }}>{`JavaScript Air`}</a>{` - A podcast I created (I also
started `}<a parentName="li" {...{
          "href": "http://angularair.com"
        }}>{`Angular Air`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/3-mins"
        }}>{`3 Minutes with Kent`}</a>{` - A
`}<a parentName="li" {...{
          "href": "https://briefs.fm"
        }}>{`briefs.fm`}</a>{` podcast I occasionally do`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/talks"
        }}>{`Talks`}</a>{` - Talks I've given`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/workshops"
        }}>{`Workshops`}</a>{` - Workshops I've given`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/egghead"
        }}>{`egghead.io`}</a>{` - My `}<a parentName="li" {...{
          "href": "https://egghead.io/?af=5236ad"
        }}>{`egghead.io`}</a>{`
instructor page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://frontendmasters.com/teachers/kentcdodds/"
        }}>{`Frontend Masters Courses`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/tech-chats"
        }}>{`Tech Chats`}</a>{` - A playlist of chats I've had with
awesome people about tech stuff
(`}<a parentName="li" {...{
          "href": "https://github.com/kentcdodds/ama/issues/125"
        }}>{`learn more`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/appearances"
        }}>{`"Appearances"`}</a>{` - A list of podcasts, interviews,
and other places that I've had chats and stuff`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      