import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import shawnPhoto from "../../../../content/podcast/01/12-shawn-wang/shawn-wang.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  shawnPhoto,
  React
};