import Container from 'components/container';
import SEO from 'components/seo';
import Layout from 'components/layout';
import BigHero from 'components/big-hero';
import { TinyLetterSubscribe } from 'components/forms/subscribe';
import theme from "../../../../config/theme";
import * as React from 'react';
export default {
  Container,
  SEO,
  Layout,
  BigHero,
  TinyLetterSubscribe,
  theme,
  React
};