import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import shirleyPhoto from "../../../../content/podcast/01/14-shirley-wu/shirley-wu.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  shirleyPhoto,
  React
};