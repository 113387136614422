module.exports = {
  siteTitle: 'Learn German with Aaron', // Navigation and Site Title
  siteTitleAlt: 'Learn German with Aaron Koivunen', // Alternative Site title for SEO
  siteTitleShort: 'aaronkoivunen', // short_name for manifest
  siteUrl: process.env.ROOT_URL || 'https://aaronkoivunen.com', // Domain of your site. No trailing slash!
  lang: 'en', // Language Tag on <html> element
  pathPrefix: '/',
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    'Learn German in an easier and more efficient way. Learn with a polyglot from München, Germany.',
  minibio: `
    <strong>Aaron Koivunen</strong> is a German teacher and software engineer. He helps people across all continents to learn German in an easier and more efficient way. He currently lives in the city of Puebla in Mexico.
  `,
  author: 'Aaron Koivunen', // Author for schemaORGJSONLD
  organization: 'Learn German',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@aaronkoivunen', // Twitter Username
  ogSiteName: 'Aaron Koivuenn', // Facebook Site Name
  ogLanguage: 'en_US',

  // Manifest and Progress color
  themeColor: '#4147DC',
  backgroundColor: '#231C42',

  // Social component
  twitter: 'https://twitter.com/aaronkoivunen/',
  twitterHandle: '@aaronkoivunen',
  github: 'https://github.com/koikar/',
  linkedin: 'https://www.linkedin.com/in/aaronkoivunen/',
  youtube: 'https://www.youtube.com/channel/UCz-BYvuntVRt_VpfR6FKXJw',
  rss: 'https://aaronkoivunen.com/blog/rss.xml',
}
