import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import justinPhoto from "../../../../content/podcast/03/06-justin-mcmurdie/justin-mcmurdie.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  justinPhoto,
  React
};