import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Guest from 'components/podcast/guest';
import cassidyPhoto from "../../../../content/podcast/01/02-cassidy-williams/cassidy-williams.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Guest,
  cassidyPhoto,
  React
};