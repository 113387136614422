import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import lindseyPhoto from "../../../../content/podcast/02/01-lindsey-kopacz/lindsey-kopacz.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  lindseyPhoto,
  React
};