import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kcd-office-hours",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kcd-office-hours",
        "aria-label": "kcd office hours permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`KCD Office Hours`}</h1>
    <p>{`Twice a week, Kent will be available for an hour to answer any questions you
have. This is especially helpful for `}<a parentName="p" {...{
        "href": "/clubs"
      }}>{`KCD Learning Clubs`}</a>{` but everyone
is invited. Office hours is held on Zoom (you need to be logged into your Zoom
account to join).`}</p>
    <p>{`To join, simply join the `}<a parentName="p" {...{
        "href": "/discord"
      }}>{`KCD Discord`}</a>{` community, and jump in the
`}<inlineCode parentName="p">{`#🏫-office-hours`}</inlineCode>{` channel to ask questions and get the links that I share with
my responses.`}</p>
    <p>{`Office Hours are held twice weekly:`}</p>
    <ul>
      <li parentName="ul">{`Mondays at
`}<a parentName="li" {...{
          "href": "https://www.thetimezoneconverter.com/?t=9%3A30%20am&tz=Mountain%20Time%20(MT)&"
        }}>{`9:30-10:30 AM Mountain Time`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://kcd.im/office-hours-zoom-monday"
        }}>{`Zoom Link`}</a>{` |
`}<a parentName="li" {...{
          "href": "https://kcd.im/office-hours-calendar-monday"
        }}>{`📆 Add to Calendar`}</a></li>
      <li parentName="ul">{`Thursdays at
`}<a parentName="li" {...{
          "href": "https://www.thetimezoneconverter.com/?t=4%3A00%20pm&tz=Mountain%20Time%20(MT)&"
        }}>{`4:00-5:00 PM Mountain Time`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://kcd.im/office-hours-zoom-thursday"
        }}>{`Zoom Link`}</a>{` |
`}<a parentName="li" {...{
          "href": "https://kcd.im/office-hours-calendar-thursday"
        }}>{`📆 Add to Calendar`}</a></li>
    </ul>
    <p>{`Get that in your calendar so you don't miss out!`}</p>
    <p>{`If you want to be notified when office hours begins, then go to the
`}<inlineCode parentName="p">{`#🤖-bots-only`}</inlineCode>{` channel and opt-in.`}</p>
    <p>{`Office Hours is live streamed to `}<a parentName="p" {...{
        "href": "https://kcd.im/YouTube"
      }}>{`my YouTube channel`}</a>{`
where you can watch recordings.`}</p>
    <h2 {...{
      "id": "cancellations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cancellations",
        "aria-label": "cancellations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Cancellations`}</h2>
    <p>{`Sometimes I can't make it to office hours (Holidays, family things, etc.). Here
are days that will be cancelled:`}</p>
    <ul>
      <li parentName="ul">{`Oct 22nd 2020`}</li>
      <li parentName="ul">{`Nov 26th 2020`}</li>
      <li parentName="ul">{`Dec 21st 2020`}</li>
      <li parentName="ul">{`Dec 24th 2020`}</li>
      <li parentName="ul">{`Dec 31st 2020`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      