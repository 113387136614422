import Transcript from 'components/podcast/transcript';
import Resources from 'components/podcast/resources';
import Homeworks from 'components/podcast/homeworks';
import Guest from 'components/podcast/guest';
import PhotoHenry from "../../../../content/podcast/02/04-henry-zhu/henry-zhu.png";
import * as React from 'react';
export default {
  Transcript,
  Resources,
  Homeworks,
  Guest,
  PhotoHenry,
  React
};