import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Container from 'components/container';
import SEO from 'components/seo';
import Layout from 'components/layout';
import BigHero from 'components/big-hero';
import { TinyLetterSubscribe } from 'components/forms/subscribe';
import theme from '../../../config/theme';
export const _frontmatter = {
  "title": "Kent C. Dodds Writes",
  "heroMessage": "Hi, I'm Kent C. Dodds and I write epic fantasy."
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function MarkdownPage({
  children,
  pageContext: {
    frontmatter
  }
}) {
  return <>
      <SEO frontmatter={frontmatter} />
      <Layout hero={<BigHero message={frontmatter.heroMessage} />} frontmatter={frontmatter} headerColor={theme.colors.white} subscribeForm={<TinyLetterSubscribe />}>
        <Container>{children}</Container>
      </Layout>
    </>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`"There is no greater agony than bearing an untold story inside you." ― Maya
Angelou`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/kent_writes"
      }}>{`Follow `}<inlineCode parentName="a">{`@kent_writes`}</inlineCode>{` on twitter`}</a>{`. It's the
twitter account where I tweet about my experience as a writer as well as nice
quotes that I enjoy like the one above.`}</p>
    <h2 {...{
      "id": "blog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blog",
        "aria-label": "blog permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Blog`}</h2>
    <p>{`You can read about my experiences on my writing blog:
`}<a parentName="p" {...{
        "href": "/writing/blog"
      }}>{`kentcdodds.com/writing/blog`}</a></p>
    <h2 {...{
      "id": "shurlan",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#shurlan",
        "aria-label": "shurlan permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Shurlan`}</h2>
    <blockquote>
      <p parentName="blockquote">{`An insecure 14-year-old surgeon struggles to learn magic that only works if
she's confident in her abilities.`}</p>
    </blockquote>
    <p>{`Planning on adding things to describe the book. Will update soon. In the
meantime, here are a few links:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/shurlan"
        }}>{`The Shurlan Manuscript`}</a>{` (work in progress)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kcd.im/shurlan-news"
        }}>{`My Writer Newsletter`}</a>{` (not the same as the
newsletter you can sign up for on my site)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      